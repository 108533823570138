<template>
  <v-navigation-drawer
    app
    right
    clipped
    width="290"
    v-model="model"
  >
    <perfect-scrollbar style="height: 100%">
      <div class="mt-4 mx-2" >
        <calendar-date-selection v-if="isCalendarView" />
        <date-selection v-else />
        <asset-selection />
        <machine-selection />
        <part-selection />
        <status-selection />
        <group-selection v-if="!isCalendarView" />
        <star-selection />
        <trial-selection />
      </div>
    </perfect-scrollbar>
  </v-navigation-drawer>
</template>

<script>
import { mapState, mapMutations, mapGetters } from 'vuex';
import CalendarDateSelection from './filters/CalendarDateSelection.vue';
import DateSelection from './filters/DateSelection.vue';
import AssetSelection from './filters/AssetSelection.vue';
import MachineSelection from './filters/MachineSelection.vue';
import PartSelection from './filters/PartSelection.vue';
import StatusSelection from './filters/StatusSelection.vue';
import GroupSelection from './filters/GroupSelection.vue';
import StarSelection from './filters/StarSelection.vue';
import TrialSelection from './filters/TrialSelection.vue';

export default {
  name: 'PlanningDrawer',
  components: {
    CalendarDateSelection,
    DateSelection,
    AssetSelection,
    MachineSelection,
    PartSelection,
    StatusSelection,
    GroupSelection,
    StarSelection,
    TrialSelection,
  },
  computed: {
    ...mapState('planningCamso', ['drawer']),
    ...mapGetters('planningCamso', ['isCalendarView']),
    model: {
      get() {
        return this.drawer;
      },
      set(val) {
        this.setDrawer(val);
      },
    },
  },
  methods: {
    ...mapMutations('planningCamso', ['setDrawer']),
  },
};
</script>
