<template>
  <div v-if="selected.status === 'notStarted'">
    <v-checkbox
        v-model="select"
        :key="selected.planid"
      >
    </v-checkbox>
  </div>
</template>

<script>

export default {
  name: 'PlanCheckboxTemplate',
  data() {
    return {
    };
  },
  props: {
    selected: {
      type: Object,
      required: true,
    },
  },
  computed: {
    select: {
      get() {
        return false;
      },
      set(data) {
        this.setSelectedPlannings(data);
      },
    },
  },
  methods: {
    setSelectedPlannings(data) {
      this.$emit('selectedData', { value: data, planid: this.selected.planid });
    },
  },
};
</script>
