<template>
  <portal to="app-extension">
    <v-toolbar
      flat
      dense
      :color="$vuetify.theme.dark ? '#121212': ''"
    >
      <template v-if="isCalendarView && !isMobile">
        <v-btn
          class="text-none"
          color="primary"
          outlined
          small
          @click="setToday"
        >
          {{ $t('planningCamso.today') }}
        </v-btn>
        <v-btn
          icon
          class="ml-2"
          @click="setPrev"
        >
          <v-icon v-text="'$prev'"></v-icon>
        </v-btn>
        <v-btn
          icon
          class="ml-2"
          @click="setNext"
        >
          <v-icon v-text="'$next'"></v-icon>
        </v-btn>
        <span class="title ml-2" v-if="calendarRef">
          {{ calendarRef.title }}
        </span>
      </template>
      <span class="title" v-else-if="!isMobile">
        {{ date }}
      </span>
      <v-spacer></v-spacer>
      <v-btn
        small
        class="text-none mr-2"
        color="primary"
        v-if="selected"
        @click="deletePlans"
      >
      <v-icon left small>mdi-delete-outline</v-icon>
         Delete Plans
      </v-btn>
      <v-btn
        small
        class="text-none mr-2"
        color="primary"
        :to="{ name: 'addProductionPlanCamso' }"
      >
        <v-icon left small>mdi-plus</v-icon>
        {{ $t('planningCamso.addPlan') }}
      </v-btn>
      <v-btn
        small
        outlined
        color="primary"
        v-if="!isMobile"
        :to="{ name: 'reorderPlansCamso' }"
        class="text-none mr-2"
      >
        <v-icon left small>mdi-swap-vertical</v-icon>
        {{ $t('planningCamso.reorderPlans') }}
      </v-btn>
      <v-menu bottom right>
        <template #activator="{ on, attrs }">
          <v-btn
            class="text-none mr-2"
            color="primary"
            outlined
            small
            v-bind="attrs"
            v-on="on"
          >
            <span>{{ $t(`planning.${typeToLabel[viewType]}`) }}</span>
            <v-icon right v-text="'mdi-menu-down'"></v-icon>
          </v-btn>
        </template>
        <v-list>
          <v-list-item @click="viewType = 'default'">
            <v-list-item-title>{{ $t('planningCamso.list') }}</v-list-item-title>
          </v-list-item>
          <v-list-item v-if="!isMobile" @click="viewType = 'month'">
            <v-list-item-title>{{ $t('planningCamso.month') }}</v-list-item-title>
          </v-list-item>
          <v-list-item v-if="!isMobile" @click="viewType = 'week'">
            <v-list-item-title>{{ $t('planningCamso.week') }}</v-list-item-title>
          </v-list-item>
          <v-list-item @click="viewType = 'day'">
            <v-list-item-title>{{ $t('planningCamso.day') }}</v-list-item-title>
          </v-list-item>
        </v-list>
      </v-menu>
      <v-badge
        overlap
        bordered
        color="primary"
        icon="mdi-check-outline"
        :value="showBadge"
      >
        <v-btn
          small
          outlined
          color="primary"
          class="text-none"
          @click="toggleDrawer(true)"
        >
          <v-icon small left>mdi-filter-variant</v-icon>
          {{ $t('planningCamso.filters') }}
        </v-btn>
      </v-badge>
    </v-toolbar>
  </portal>
</template>

<script>
import {
  mapActions,
  mapState,
  mapMutations,
  mapGetters,
} from 'vuex';
import { formatDate } from '@shopworx/services/util/date.service';

export default {
  name: 'PlanningToolbar',
  data() {
    return {
      typeToLabel: {
        default: 'list',
        month: 'month',
        week: 'week',
        day: 'day',
      },
      selected: false,
    };
  },
  computed: {
    ...mapState('planningCamso', [
      'drawer',
      'view',
      'today',
      'calendarRef',
      'selectedPlans',
    ]),
    isMobile() {
      return this.$vuetify.breakpoint.xsOnly;
    },
    ...mapGetters('webApp', ['filters']),
    ...mapGetters('helper', ['locale']),
    ...mapGetters('planningCamso', ['isCalendarView']),
    viewType: {
      get() {
        return this.view;
      },
      set(view) {
        this.setView(view);
      },
    },
    showBadge() {
      const isMachineFilter = this.filters.machinename
        && !this.filters.machinename.value.includes('All');
      const isPartFilter = this.filters.partname
        && !this.filters.partname.value.includes('All');
      const isStatusFilter = this.filters.status
        && this.filters.status.value.length !== 4;
      const isStarredFilter = this.filters.starred
        && this.filters.starred.value;
      const isTrialFilter = this.filters.trial
        && this.filters.trial.value;
      return isMachineFilter || isPartFilter || isStatusFilter || isStarredFilter || isTrialFilter;
    },
    date() {
      let dateText = '';
      if (this.filters && this.filters.date && this.filters.date.value) {
        const start = formatDate(
          new Date(this.filters.date.value[0]),
          'PP',
          { locale: this.locale },
        );
        dateText = start;
        let end = '';
        if (this.filters.date.value.length === 2) {
          end = formatDate(
            new Date(this.filters.date.value[1]),
            'PP',
            { locale: this.locale },
          );
        }
        dateText = `${start} - ${end}`;
      }
      return dateText;
    },
  },
  watch: {
    selectedPlans() {
      if (this.selectedPlans && this.selectedPlans.length) {
        const findAnyPlan = this.selectedPlans.findIndex((obj) => obj.value === true);
        if (findAnyPlan >= 0) {
          this.selected = true;
        } else {
          this.selected = false;
        }
      } else {
        this.selected = false;
      }
    },
  },
  methods: {
    ...mapActions('planningCamso', ['deletePlanByPlanId']),
    ...mapMutations('planningCamso', [
      'toggleDrawer',
      'setView',
      'setCalendarFocus',
    ]),
    setToday() {
      this.setCalendarFocus(this.today);
    },
    setPrev() {
      this.calendarRef.prev();
    },
    setNext() {
      this.calendarRef.next();
    },
    async deletePlans() {
      if (this.selectedPlans && this.selectedPlans.length) {
        const filteredPlans = this.selectedPlans.filter((item) => item.value === true);
        const planids = filteredPlans.map((item) => item.planid);
        const planidsString = planids.join(', ');
        if (await this.$root.$confirm.open(
          this.$t('planningCamso.confirmDeleteTitle'),
          this.$t('planningCamso.confirmDeleteSubtitle', { id: planidsString }),
        )) {
          const deletedPlans = [];
          // eslint-disable-next-line
          for (let index = 0; index < filteredPlans.length; index++) {
            const element = filteredPlans[index];
            // eslint-disable-next-line
            const deleted = await this.deletePlanByPlanId({
              planId: element.planid,
              listType: 'all',
            });
            deletedPlans.push(deleted);
          }
          if (deletedPlans.length === filteredPlans.length) {
            this.selected = false;
          }
        }
      }
    },
  },
};
</script>
