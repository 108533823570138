<template>
  <v-tooltip bottom>
    <template #activator="{ on, attrs }">
      <v-btn
        icon
        v-on="on"
        v-bind="attrs"
        :small="small"
        color="error"
        @click="updatePlan"
      >
        <v-icon>mdi-close-octagon-outline</v-icon>
      </v-btn>
    </template>
    {{ $t('planningCamso.abortPlan') }}
  </v-tooltip>
</template>

<script>
import { mapActions } from 'vuex';

export default {
  name: 'AbortPlan',
  props: {
    planId: {
      type: String,
      required: true,
    },
    small: {
      type: Boolean,
      default: false,
    },
    listType: {
      type: String,
      default: 'all',
    },
  },
  methods: {
    ...mapActions('planningCamso', [
      'updatePlanByPlanId',
      'updateCuringSummary',
    ]),
    async updatePlan() {
      if (await this.$root.$confirm.open(
        this.$t('planningCamso.confirmAbortTitle'),
        this.$t('planningCamso.confirmAbortSubtitle', { id: this.planId }),
      )) {
        await this.updatePlanByPlanId({
          planId: this.planId,
          payload: {
            status: 'abort',
            actualend: new Date().getTime(),
          },
          listType: this.listType,
        });
        await this.updateCuringSummary({
          planId: this.planId,
          payload: {
            status: 'abort',
            cyclestatus: 'abort',
            validcycle: false,
            timestamp: new Date().getTime(),
            lastupdatedtime: new Date().getTime(),
          },
        });
      }
    },
  },
};
</script>
