<template>
  <v-select
    dense
    outlined
    return-object
    v-model="asset"
    :items="assetList"
    label="Asset"
    prepend-inner-icon="mdi-progress-check"
  ></v-select>
</template>

<script>
import {
  mapState,
  mapMutations,
  mapGetters,
} from 'vuex';

const FIELD_NAME = 'assetid';

export default {
  name: 'AssetSelection',
  computed: {
    ...mapState('planningCamso', ['assetList']),
    ...mapGetters('webApp', ['filters']),
    isStatusFilterInactive() {
      return !Object
        .keys(this.filters)
        .includes(FIELD_NAME);
    },
    asset: {
      get() {
        const statusFilter = this.filters && this.filters[FIELD_NAME];
        if (statusFilter) {
          const value = this.assetList.find((s) => s.value === statusFilter.value);
          if (value) {
            return value;
          }
        }
        return this.assetList[0];
      },
      set(statusVal) {
        this.setStatusFilter(statusVal);
      },
    },
  },
  created() {
    if (this.isStatusFilterInactive) {
      this.setStatusFilter(this.assetList[0]);
    }
  },
  methods: {
    ...mapMutations('webApp', ['setFilter']),
    setStatusFilter(asset) {
      this.setFilter({
        field: FIELD_NAME,
        value: {
          value: asset.value,
          operation: 'eq',
        },
      });
    },
  },
};
</script>
