<template>
  <validation-observer ref="form" #default="{ handleSubmit }">
    <v-form @submit.prevent="handleSubmit(onSaveAndAddNew)">
      <v-container fluid>
        <v-row
          justify="center"
          :no-gutters="$vuetify.breakpoint.smAndDown"
        >
          <v-col cols="12" xl="9" class="text-justify">
            <v-card flat class="transparent">
              <v-card-text class="pt-0">
                <v-row>
                  <v-col cols="12" sm="4">
                    <validation-provider
                      name="asset"
                      rules="required"
                      #default="{ errors }"
                    >
                      <v-select
                        filled
                        :label="$t('planningCamso.asset')"
                        :items="assetList"
                        :error-messages="errors"
                        :disabled="saving"
                        v-model="selectedAsset"
                        @change="onAssetSelection"
                      ></v-select>
                    </validation-provider>
                  </v-col>
                  <v-col cols="12" sm="4">
                    <validation-provider
                      name="partname"
                      rules="required"
                      #default="{ errors }"
                    >
                      <v-autocomplete
                        clearable
                        filled
                        :label="$t('planningCamso.part')"
                        :items="partList"
                        return-object
                        :error-messages="errors"
                        :disabled="saving || !selectedAsset"
                        :loading="fetchingParts"
                        item-text="partnumber"
                        v-model="selectedPart"
                        :title="selectedPart ? selectedPart.partname : ''"
                        @change="onPartSelection"
                      >
                        <template #item="{ item }">
                          <v-list-item-content>
                            <v-list-item-title v-text="item.partnumber"></v-list-item-title>
                            <v-list-item-subtitle v-text="item.partname"></v-list-item-subtitle>
                          </v-list-item-content>
                        </template>
                      </v-autocomplete>
                    </validation-provider>
                  </v-col>
                  <v-col cols="12" sm="4">
                    <validation-provider
                      name="machinename"
                      rules="required"
                      #default="{ errors }"
                    >
                      <v-autocomplete
                        clearable
                        filled
                        :label="$t('planningCamso.machine')"
                        :items="machineList"
                        :error-messages="errors"
                        :disabled="saving || !selectedPart"
                        :loading="fetchingMatrix"
                        item-text="machinename"
                        item-value="machinename"
                        v-model="selectedMachine"
                        :title="selectedMachine || ''"
                        @change="setPlanParameters"
                      ></v-autocomplete>
                    </validation-provider>
                  </v-col>
                  <v-col cols="12" sm="4" v-if="isCuring">
                    <validation-provider
                      name="equipmentname"
                      rules="required"
                      #default="{ errors }"
                    >
                      <v-autocomplete
                        clearable
                        filled
                        :label="$t('planningCamso.equipment')"
                        :items="equipmentList"
                        :error-messages="errors"
                        :disabled="saving || !selectedPart"
                        :loading="fetchingMatrix"
                        item-text="equipmentname"
                        item-value="equipmentname"
                        v-model="selectedMold"
                        :title="selectedMold || ''"
                        @change="setPlanParameters"
                      ></v-autocomplete>
                    </validation-provider>
                  </v-col>
                  <v-col cols="12" sm="4" v-if="isCuring">
                    <validation-provider
                      name="machine"
                      rules="required"
                      #default="{ errors }"
                    >
                      <v-autocomplete
                        clearable
                        filled
                        label="Phase"
                        :items="treadMachines"
                        :error-messages="errors"
                        :disabled="saving || !selectedPart"
                        :loading="fetchingMatrix"
                        item-text="machinename"
                        item-value="machinename"
                        v-model="selectedTreadMachine"
                        :title="selectedTreadMachine || ''"
                        @change="setPlanParameters"
                      ></v-autocomplete>
                    </validation-provider>
                  </v-col>
                  <v-col cols="12" sm="4" v-if="isCuring">
                    <v-checkbox
                      hide-details
                      label="Shoulder strip required?"
                      :disabled="saving"
                      v-model="plan.shoulderstriprequired"
                    ></v-checkbox>
                  </v-col>
                </v-row>
                <div class="title mb-2">
                  {{ $t('planningCamso.operationalParams') }}
                  <v-btn
                    small
                    outlined
                    class="text-none"
                    :disabled="!selectedMatrix || saving"
                    @click="editParams = !editParams"
                    color="primary"
                  >
                    <template v-if="!editParams">
                      <v-icon left small>mdi-pencil-outline</v-icon>
                      {{ $t('planningCamso.editButton') }}
                    </template>
                    <template v-else>
                      <v-icon left small>mdi-check</v-icon>
                      {{ $t('planningCamso.doneButton') }}
                    </template>
                  </v-btn>
                </div>
                <v-row>
                  <v-col cols="12" sm="4" v-if="isCarcass">
                    <validation-provider
                      name="cycletime"
                      :rules="`${isCarcass
                        ? 'required|min_value:1'
                        : ''}`"
                      #default="{ errors }"
                    >
                      <v-text-field
                        :label="$t('planningCamso.cycletime')"
                        type="number"
                        v-if="isCarcass"
                        :disabled="!editParams || saving"
                        :error-messages="errors"
                        prepend-inner-icon="mdi-clock-fast"
                        outlined
                        :suffix="$t('planningCamso.mins')"
                        v-model.number="plan.stdcycletime"
                        hide-details="auto"
                      ></v-text-field>
                    </validation-provider>
                  </v-col>
                  <v-col cols="12" sm="4" v-if="isCuring">
                    <validation-provider
                      name="curingtime"
                      :rules="`${isCuring
                        ? 'required|min_value:1'
                        : ''}`"
                      #default="{ errors }"
                    >
                      <v-text-field
                        label="Curing time"
                        type="number"
                        v-if="isCuring"
                        :disabled="!editParams || saving"
                        :error-messages="errors"
                        prepend-inner-icon="mdi-clock-fast"
                        outlined
                        suffix="mins"
                        v-model.number="plan.curingtime"
                        hide-details="auto"
                      ></v-text-field>
                    </validation-provider>
                  </v-col>
                  <v-col cols="12" sm="4" v-if="isCuring">
                    <validation-provider
                      name="handlingtime"
                      :rules="`${isCuring
                        ? 'required|min_value:1'
                        : ''}`"
                      #default="{ errors }"
                    >
                      <v-text-field
                        label="Handling time"
                        type="number"
                        v-if="isCuring"
                        :disabled="!editParams || saving"
                        :error-messages="errors"
                        prepend-inner-icon="mdi-cached"
                        outlined
                        suffix="mins"
                        v-model.number="plan.handlingtime"
                        hide-details="auto"
                      ></v-text-field>
                    </validation-provider>
                  </v-col>
                  <v-col cols="12" sm="4" v-if="isCuring">
                    <v-text-field
                      label="Comments (Optional)"
                      type="text"
                      v-if="isCuring"
                      :disabled="!editParams || saving"
                      prepend-inner-icon="mdi-message-outline"
                      outlined
                      v-model="plan.comments"
                      hide-details="auto"
                    ></v-text-field>
                  </v-col>
                </v-row>
                <div class="title mt-4 mb-2">
                  {{ $t('planningCamso.planDetails') }}
                </div>
                <v-row>
                  <v-col cols="12" sm="4">
                    <validation-provider
                      name="quantity"
                      rules="required|numeric|min_value:1"
                      #default="{ errors }"
                    >
                      <v-text-field
                        :label="$t('planningCamso.quantity')"
                        type="number"
                        :disabled="saving"
                        :error-messages="errors"
                        prepend-inner-icon="mdi-package-variant-closed"
                        outlined
                        :suffix="$t('planningCamso.pieces')"
                        v-model="plan.plannedquantity"
                        hide-details="auto"
                        @change="onQuantityChange"
                      ></v-text-field>
                    </validation-provider>
                  </v-col>
                  <v-col cols="12" sm="4">
                    <validation-provider
                      name="scheduledstart"
                      rules="required|greater_than_now"
                      #default="{ errors }"
                    >
                      <v-text-field
                        :label="$t('planningCamso.scheduledStartTime')"
                        type="datetime-local"
                        :error-messages="errors"
                        prepend-inner-icon="mdi-calendar-check-outline"
                        outlined
                        v-model="plan.scheduledstart"
                        hide-details="auto"
                        :disabled="saving"
                        @change="fetchEstimatedEnd"
                      >
                        <template #append-outer>
                          <v-tooltip bottom>
                            <template #activator="{ on, attrs }">
                              <v-icon
                                v-text="'$info'"
                                v-on="on"
                                v-bind="attrs"
                              ></v-icon>
                            </template>
                            <ul>
                              <li>
                                Scheduled start time is only used in calculating
                                planned vs actual performance.
                              </li>
                              <li>
                                Updating scheduled start time will not affect
                                the execution order of the plan.
                              </li>
                              <li>
                                Plans are picked automatically in FIFO fashion.
                              </li>
                              <li>
                                To update the execution order, please visit
                                <strong>re-order plans section</strong>.
                              </li>
                            </ul>
                          </v-tooltip>
                        </template>
                      </v-text-field>
                    </validation-provider>
                  </v-col>
                  <v-col cols="6" sm="2">
                    <v-checkbox
                      hide-details
                      :label="$t('planningCamso.markAsStar')"
                      :disabled="saving"
                      v-model="plan.starred"
                    ></v-checkbox>
                  </v-col>
                  <v-col cols="6" sm="2">
                    <v-checkbox
                      hide-details
                      :label="$t('planningCamso.markAsTrial')"
                      :disabled="saving"
                      v-model="plan.trial"
                    ></v-checkbox>
                  </v-col>
                </v-row>
                <div>
                  {{ $t('planningCamso.estimatedEnd') }}
                  <strong>{{ estimatedEndDisplay }}</strong>
                </div>
              </v-card-text>
              <v-card-actions>
                <v-btn
                  text
                  color="primary"
                  class="text-none"
                  :disabled="saving"
                  @click="exit"
                >
                  {{ $t('planningCamso.exit') }}
                </v-btn>
                <v-spacer></v-spacer>
                <v-btn
                  outlined
                  color="primary"
                  class="text-none"
                  :disabled="savingAndNew"
                  :loading="savingAndExit"
                  @click="onSaveAndExit"
                >
                  {{ $t('planningCamso.saveExit') }}
                </v-btn>
                <v-btn
                  color="primary"
                  class="text-none"
                  :disabled="savingAndExit"
                  :loading="savingAndNew"
                  type="submit"
                >
                  {{ $t('planningCamso.saveAddNew') }}
                </v-btn>
              </v-card-actions>
            </v-card>
          </v-col>
        </v-row>
      </v-container>
    </v-form>
  </validation-observer>
</template>

<script>
import {
  mapState, mapActions, mapGetters, mapMutations,
} from 'vuex';
import { sortArray } from '@shopworx/services/util/sort.service';
import { formatDate } from '@shopworx/services/util/date.service';

export default {
  name: 'AddPlan',
  data() {
    return {
      selectedAsset: null,
      selectedPart: null,
      selectedTreadMachine: null,
      editParams: false,
      savingAndExit: false,
      savingAndNew: false,
      fetchingParts: false,
      fetchingMatrix: false,
      partMatrixList: [],
      plan: null,
      selectedMachine: null,
      selectedMold: null,
    };
  },
  computed: {
    ...mapState('planningCamso', [
      'parts',
      'machines',
      'molds',
      'partMatrix',
      'assetList',
    ]),
    ...mapGetters('planningCamso', ['getAssetName']),
    ...mapGetters('helper', ['locale']),
    ...mapGetters('webApp', ['filters']),
    isCarcass() {
      let result = false;
      if (this.selectedAsset) {
        result = this.getAssetName(this.selectedAsset) === 'building';
      }
      return result;
    },
    isCuring() {
      let result = false;
      if (this.selectedAsset) {
        result = this.getAssetName(this.selectedAsset) === 'curing';
      }
      return result;
    },
    saving() {
      return this.savingAndExit || this.savingAndNew;
    },
    partList() {
      return sortArray(this.parts, 'partname').filter((p) => p.assetid === this.selectedAsset);
    },
    treadMachines() {
      const list = this.machines
        .filter((val) => (val.assetid === 7));
      return sortArray(list, 'machinename');
    },
    machineList() {
      let list = [...this.partMatrixList];
      if (this.selectedMold) {
        list = this.partMatrixList
          .filter((val) => (val.equipmentname === this.selectedMold));
        return sortArray(list, 'machinename');
      }
      return sortArray(list, 'machinename');
    },
    equipmentList() {
      let list = [...this.partMatrixList];
      if (this.selectedMachine) {
        list = this.partMatrixList
          .filter((val) => (val.machinename === this.selectedMachine));
        return sortArray(list, 'equipmentname');
      }
      return sortArray(list, 'equipmentname');
    },
    estimatedEndDisplay() {
      let res = '';
      if (this.plan.scheduledend) {
        res = formatDate(
          new Date(this.plan.scheduledend),
          'PPpp',
          { locale: this.locale },
        );
      }
      return res;
    },
    selectedMatrix() {
      const isCarcassSelected = this.isCarcass && this.selectedPart && this.selectedMachine;
      const isCuringSelected = this.isCuring
        && this.selectedPart && this.selectedMachine && this.selectedMold;
      if (isCarcassSelected) {
        return this.partMatrixList.find((matrix) => (
          matrix.partnumber === this.selectedPart.partnumber
          && matrix.machinename === this.selectedMachine
        ));
      }
      if (isCuringSelected) {
        return this.partMatrixList.find((matrix) => (
          matrix.partnumber === this.selectedPart.partnumber
          && matrix.machinename === this.selectedMachine
          && matrix.moldnumber === this.selectedMold
        ));
      }
      return null;
    },
  },
  created() {
    this.selectedAsset = this.filters?.assetid?.value || this.assetList[0].value;
    this.initPlan();
  },
  methods: {
    ...mapMutations('helper', ['setAlert']),
    ...mapActions('planningCamso', [
      'fetchPartMatrix',
      'getScheduledEnd',
      'fetchLastPlan',
      'createPlan',
    ]),
    initPlan() {
      this.plan = {
        assetid: 0,
        partname: '',
        partnumber: '',
        machinename: '',
        moldnumber: '',
        stdcycletime: '',
        curingtime: '',
        handlingtime: '',
        plannedquantity: '',
        treadmachine: '',
        shoulderstriprequired: false,
        scheduledstart: formatDate(new Date(Math.ceil(new Date() / 9e5) * 9e5), 'yyyy-MM-dd\'T\'HH:mm'),
        scheduledend: '',
        starred: false,
        trial: false,
        sortindex: 0,
        status: 'notStarted',
        comments: '',
      };
    },
    clear() {
      this.initPlan();
      this.partMatrixList = [];
      this.selectedMachine = null;
      this.selectedTreadMachine = null;
      this.selectedMold = null;
      this.$nextTick(() => {
        this.$refs.form.reset();
      });
    },
    async onAssetSelection() {
      this.fetchingParts = true;
      this.clear();
      this.fetchingParts = false;
    },
    async onPartSelection() {
      this.clear();
      if (this.selectedPart) {
        this.fetchingMatrix = true;
        this.partMatrixList = await this.fetchPartMatrix(this.selectedPart);
        this.fetchingMatrix = false;
      }
    },
    async setPlanParameters() {
      if (this.selectedMatrix) {
        const {
          stdcycletime,
          curingtime,
          handlingtime,
          machinename,
          moldnumber,
        } = this.selectedMatrix;
        this.plan.assetid = this.selectedAsset;
        this.plan.partname = this.selectedPart.partname;
        this.plan.partnumber = this.selectedPart.partnumber;
        this.plan.machinename = machinename;
        if (this.isCarcass) {
          this.plan.stdcycletime = +stdcycletime;
        }
        if (this.isCuring) {
          this.plan.moldnumber = moldnumber;
          this.plan.curingtime = +curingtime;
          this.plan.handlingtime = +handlingtime;
          this.plan.treadmachine = this.selectedTreadMachine;
        }
      }
    },
    async fetchEstimatedEnd() {
      if (this.plan.plannedquantity && this.plan.scheduledstart) {
        let runTime = 0;
        if (this.isCarcass) {
          runTime = +this.plan.plannedquantity * (+this.plan.stdcycletime * 60 * 1000);
        } else if (this.isCuring) {
          runTime = +this.plan.plannedquantity
          * ((+this.plan.curingtime * 60 * 1000) + (+this.plan.handlingtime * 60 * 1000));
        }
        this.plan.scheduledend = await this.getScheduledEnd({
          start: new Date(this.plan.scheduledstart).getTime(),
          duration: runTime,
        });
      } else {
        this.plan.scheduledend = '';
      }
    },
    onQuantityChange() {
      this.fetchEstimatedEnd();
    },
    async setSortIndex() {
      const lastPlan = await this.fetchLastPlan();
      if (lastPlan) {
        this.plan.sortindex = lastPlan.sortindex + 100;
      } else {
        this.plan.sortindex = 100;
      }
    },
    sanatizePayload(payload) {
      // eslint-disable-next-line
      delete payload._id;
      delete payload.questions;
      delete payload.elementId;
      delete payload.customerId;
      delete payload.siteId;
      delete payload.userId;
      delete payload.modifiedtimestamp;
      delete payload.createdTimestamp;
      return payload;
    },
    exit() {
      this.clear();
      this.selectedPart = null;
      this.$router.push({ name: 'productionPlanningCamso' });
    },
    async save() {
      await this.setSortIndex();
      const machine = this.machines.find((m) => m.machinename === this.selectedMachine);
      let payload = {
        ...this.plan,
        scheduledstart: new Date(this.plan.scheduledstart).getTime(),
        plandate: formatDate(new Date(this.plan.scheduledstart).getTime(), 'yyyyMMdd'),
        ...this.selectedPart,
        ...machine,
      };
      if (this.isCuring) {
        const mold = this.molds.find((m) => m.moldnumber === this.selectedMold);
        payload = {
          ...mold,
          ...payload,
        };
      }
      payload = this.sanatizePayload(payload);
      const created = await this.createPlan(payload);
      if (created) {
        this.setAlert({
          show: true,
          type: 'success',
          message: 'PLAN_CREATED',
        });
      } else {
        this.setAlert({
          show: true,
          type: 'error',
          message: 'ERROR_CREATING_PLAN',
        });
      }
      return created;
    },
    async onSaveAndAddNew() {
      this.savingAndNew = true;
      const created = await this.save();
      if (created) {
        this.clear();
        this.selectedPart = null;
      }
      this.savingAndNew = false;
    },
    async onSaveAndExit() {
      const isValid = await this.$refs.form.validate();
      if (isValid) {
        this.savingAndExit = true;
        const created = await this.save();
        if (created) {
          this.exit();
        }
        this.savingAndExit = false;
      }
    },
  },
};
</script>
