<template>
  <div style="height:100%">
    <portal to="app-header">
      <v-btn class="mb-1" icon @click="goBack">
        <v-icon>mdi-arrow-left</v-icon>
      </v-btn>
      <span>{{ $t('planningCamso.addPlan') }}</span>
      <v-btn
        small
        outlined
        color="primary"
        @click="goToImport"
        v-if="!isMobile"
        class="text-none ml-2"
      >
        {{ $t('planningCamso.importPlan') }}
      </v-btn>
    </portal>
    <v-container fill-height v-if="loading">
      <v-row
        align="center"
        justify="center"
        :no-gutters="$vuetify.breakpoint.smAndDown"
      >
        <v-col cols="12" align="center">
          <v-progress-circular
            indeterminate
            color="primary"
            size="72"
          ></v-progress-circular>
        </v-col>
        <v-col cols="12" align="center">
          <div class="headline">
            {{ $t('planningCamso.loadingTitle') }}
          </div>
          <div class="title">
            {{ $t('planningCamso.fetchingPlan') }}
          </div>
        </v-col>
      </v-row>
    </v-container>
    <add-plan v-else />
  </div>
</template>

<script>
import { mapState, mapActions } from 'vuex';
import AddPlan from '../components/actions/AddPlan.vue';

export default {
  name: 'AddProductionPlan',
  components: {
    AddPlan,
  },
  data() {
    return {
      loading: false,
    };
  },
  computed: {
    ...mapState('planningCamso', ['machines', 'parts', 'molds']),
    isMobile() {
      return this.$vuetify.breakpoint.smAndDown;
    },
  },
  async created() {
    const machinesNotAvailable = this.machines.length === 0;
    const partsNotAvailable = this.parts.length === 0;
    const moldsNotAvailable = this.molds.length === 0;
    if (machinesNotAvailable || partsNotAvailable || moldsNotAvailable) {
      this.loading = true;
      await Promise.all([
        this.fetchMachines(),
        this.fetchParts(),
        this.fetchMolds(),
      ]);
      this.loading = false;
    }
  },
  methods: {
    ...mapActions('planningCamso', [
      'fetchMachines',
      'fetchParts',
      'fetchMolds',
    ]),
    goBack() {
      this.$router.push({ name: 'productionPlanningCamso' });
    },
    goToImport() {
      this.$router.push({
        name: 'productionPlanSettingsCamso',
        params: { id: 'import' },
      });
    },
  },
};
</script>
