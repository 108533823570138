<template>
  <v-data-table
    :items="items"
    :headers="headers"
    hide-default-footer
    disable-pagination
    item-key="_id"
  >
    <template #item.checkbox="{ item }">
      <plan-checkbox-template :selected="item" @selectedData="handleSelectedPlans"/>
    </template>
    <!-- eslint-disable-next-line -->
    <template #item.status="{ item }">
      <plan-status-template :status="item.status" />
    </template>
    <!-- eslint-disable-next-line -->
    <template #item.planid="{ item }">
      <plan-id-template :planid="item.planid" :trial="item.trial" />
    </template>
    <!-- eslint-disable-next-line -->
    <template #item.actualstart="{ item }">
      <span v-if="item.actualstart">
        <plan-start-template :datetime="item.actualstart" />
      </span>
      <span v-else>
        <plan-start-template :datetime="item.scheduledstart" />
      </span>
    </template>
    <!-- eslint-disable-next-line -->
    <template #item.action="{ item }">
      <plan-actions-template :plan="item" />
    </template>
  </v-data-table>
</template>

<script>
import { mapMutations } from 'vuex';
import PlanStatusTemplate from './PlanStatusTemplate.vue';
import PlanIdTemplate from './PlanIdTemplate.vue';
import PlanStartTemplate from './PlanStartTemplate.vue';
import PlanCheckboxTemplate from './PlanCheckboxTemplate.vue';
import PlanActionsTemplate from './PlanActionsTemplate.vue';

export default {
  name: 'StatusView',
  components: {
    PlanCheckboxTemplate,
    PlanStatusTemplate,
    PlanIdTemplate,
    PlanStartTemplate,
    PlanActionsTemplate,
  },
  props: {
    items: {
      type: Array,
      required: true,
    },
  },
  watch: {
    selectedPlanning() {
      const plandata = [...this.selectedPlanning];
      const filteredData = plandata.reduce((acc, curr) => {
        const index = acc.findIndex((item) => item.planid === curr.planid);
        if (index !== -1) {
          acc[index].value = curr.value;
        } else {
          acc.push(curr);
        }
        return acc;
      }, []);
      this.setSelectedPlans(filteredData);
    },
  },
  data() {
    return {
      selectedPlanning: [],
      headers: [
        { text: '', value: 'checkbox', width: '1%' },
        { text: '', value: 'status' },
        { text: this.$t('planningCamso.plan'), value: 'planid' },
        { text: this.$t('planningCamso.machine'), value: 'machinename' },
        {
          text: this.$t('planningCamso.partnumber'),
          value: 'partnumber',
        },
        {
          text: this.$t('planningCamso.partname'),
          value: 'partname',
          width: '20%',
        },
        { text: this.$t('planningCamso.plannedQty'), value: 'plannedquantity' },
        { text: this.$t('planningCamso.planStart'), value: 'actualstart' },
        {
          text: this.$t('planningCamso.action'),
          value: 'action',
          sortable: false,
          width: '15%',
        },
      ],
    };
  },
  methods: {
    ...mapMutations('planningCamso', ['setSelectedPlans']),
    handleSelectedPlans(data) {
      this.selectedPlanning.push(data);
    },
  },
  beforeDestroy() {
    this.setSelectedPlans([]);
  },
};
</script>
